import Image from "next/image";
import cn from "clsx";
import { Text } from "@components/ui";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { PrismicNextImage } from "@prismicio/next";
import { SliderSlick } from "@components/commun";
import NutriscorePicto from "@static/images/feature/nutriscore.png";
import style from "./SliderProducts.module.scss";

export type SliderProductsProps =
  SliceComponentProps<Content.SliderProductsSlice>;

const SliderProducts = ({ slice }: SliderProductsProps): JSX.Element => {
  const slidersettings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "160px",
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerPadding: "40px",
        },
      },
    ],
  };

  const isMeals = slice.primary.category?.toLowerCase().indexOf("plat") ?? -1;

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={style.root}
    >
      <div className={style.items}>
        <SliderSlick currentIndex={0} settings={slidersettings}>
          {slice.items.map((item) => (
            <div className={style.item}>
              <Text variant="span">{item.title}</Text>
              <div className={style.imgWrap}>
                <PrismicNextImage field={item.feature} />
                {isMeals > -1 && (
                  <div className={cn("nutriscore", style.nutriscore)}>
                    <Image src={NutriscorePicto} alt="" />
                  </div>
                )}
              </div>
            </div>
          ))}
        </SliderSlick>
      </div>
    </section>
  );
};

export default SliderProducts;
